import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ServiceHero from "../components/service-hero";
import OurServicesSection from "../components/our-services-section";
import Reviews from "../components/reviews";
import { checkPropertiesForNull } from "../../utils";

const GalleryPage = () => {
  const data = useStaticQuery(graphql`
    query {
      pageData: wpPage(slug: { eq: "gallery" }) {
        galleryFields {
          galleryBanner {
            galleryBannerHeading
            galleryBannerBackgroundImage {
              node {
                altText
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
          imageGallery {
            nodes {
              altText
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
          galleryReviews {
            galleryReviewsHeading
          }
          galleryServicesCardsSection {
            galleryServicesCardsHeading
            galleryServicesCards {
              nodes {
                ... on WpService {
                  title
                  servicesPostFields {
                    serviceUrl {
                      target
                      title
                      url
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      localFile {
                        publicURL
                        childImageSharp {
                          gatsbyImageData(
                            formats: [AUTO, WEBP]
                            quality: 100
                            transformOptions: {
                              cropFocus: CENTER
                              fit: CONTAIN
                            }
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        seoFields {
          title
          opengraphTitle
          opengraphDescription
          metaDescription
          localBusinessSchema
          image {
            node {
              altText
              localFile {
                childImageSharp {
                  original {
                    height
                    width
                  }
                }
                publicURL
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const {
    pageData: { seoFields, galleryFields },
  } = data;

  const {
    galleryBanner,
    imageGallery,
    galleryReviews,
    galleryServicesCardsSection,
  } = galleryFields;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Gallery",
        item: {
          url: `${siteUrl}/gallery`,
          id: `${siteUrl}/gallery`,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFields?.title}
        description={seoFields?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}`,
          title: `${seoFields?.opengraphTitle}`,
          description: `${seoFields?.opengraphDescription}`,
          images: [
            {
              url: `${seoFields?.image?.node.localFile.publicURL}`,
              width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFields?.image?.node.altText}`,
            },
          ],
        }}
      />

      <Layout>
        {galleryBanner && (
          <section>
            <ServiceHero
              title={galleryBanner.galleryBannerHeading}
              backgroundImage={
                galleryBanner.galleryBannerBackgroundImage.node?.localFile
                  .childImageSharp.gatsbyImageData
              }
            />
          </section>
        )}

        {imageGallery &&
          imageGallery.nodes &&
          imageGallery.nodes.length > 0 && (
            <section className="py-lg-8 py-5 gradient">
              <Container>
                <Row className="gx-md-5">
                  {imageGallery.nodes.map((item) => (
                    <Col className="pb-5" md={6} lg={4} key={uuidv4()}>
                      <GatsbyImage
                        className="w-100"
                        image={item?.localFile.childImageSharp.gatsbyImageData}
                        alt={item?.altText}
                      />
                    </Col>
                  ))}
                </Row>
              </Container>
            </section>
          )}

        <section>
          <Reviews title={galleryReviews?.galleryReviewsHeading} />
        </section>

        {galleryServicesCardsSection &&
          !checkPropertiesForNull(galleryServicesCardsSection, [
            "galleryServicesCardsHeading",
          ]) && (
            <OurServicesSection
              title={galleryServicesCardsSection.galleryServicesCardsHeading}
              services={galleryServicesCardsSection.galleryServicesCards?.nodes}
            />
          )}
      </Layout>
    </>
  );
};

export default GalleryPage;
